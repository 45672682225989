// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
import './components/**/*.ts';
import './redirect';
import './logout-handler';
import './resource-download-modal';

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean;
  env: string;
  browser: {
    name: string;
    version: string;
  };
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  };
}
declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
    _downloadableResourcePath: any; //resource download path
    _orderMaterialData: any;
  }
}

(() => {
  // adds --scrollbar-width variable to the body tag
  const addScrollbarWidthVariable = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer?.parentNode?.removeChild(outer);
    if (scrollbarWidth) {
      document.body.style.setProperty(
        '--scrollbar-width',
        scrollbarWidth + 'px'
      );
    }
  };

  let loadTimer;
  // adds a class to body to make the body visible
  const makeBodyVisible = () => {
    document.body.classList.add('visible');

    if (loadTimer) {
      clearTimeout(loadTimer);
    }
  };

  // by default body has opacity 0, and on window load event, or before 5000ms adding a class to body to make the opacity 1
  // this gives a decent animation how the page is loading
  const handleLoadEvent = () => {
    if (document.readyState === 'complete') {
      makeBodyVisible();
    } else {
      window.addEventListener('load', makeBodyVisible);
      loadTimer = setTimeout(makeBodyVisible, 5000);
    }
  };

  const init = () => {
    addScrollbarWidthVariable();
    handleLoadEvent();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
